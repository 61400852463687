<template>
  <Base />
</template>

<script>
  import Base from "@/components/Base.vue"

  export default {
    name: 'App',
    components: {
      Base
    }
  }
</script>

<style>
  body {
    background-image: url("./assets/chalkboard.jpg");
    background-repeat: repeat;
    margin: 0;
    overflow-x: hidden;
  }
</style>