<template>
  <div>
    <Header />
    <div class="album-container animate__animated animate__fadeIn animate__faster">
      <div v-bind:class="['panel-top', bandAbbrv]">
        <div class="back-flex-group">
          <div class="back-arrow-container">
            <img class="back-arrow" @click="navigateToBand()" src="@/assets/icons/back-arrow.png" alt="Back" />
            <img class="back-arrow-hover" @click="navigateToBand()" src="@/assets/icons/back-arrow-hover.png" alt="Back"/>
          </div>
          <div>
            <img v-bind:class="['back-band-image', bandAbbrv]" :src="getBandUrl()" />
          </div>
        </div>
        <img class="panel-header" src="@/assets/panel-headers/album.png" alt="Album" />
        <div class="end-flex-group"></div>
      </div>
      <div v-bind:class="['panel-background', bandAbbrv]">
        <img class="album-image" :src="getAlbumUrl()" :alt="album.name" />
        <div class="album-title-container">
          <p class="album-title">{{ album.name }}</p>
          <img class="album-title-line" src="@/assets/line.png" alt="Decorative Line"/>
          <p class="description-text basic-panel-text">
            {{album.description}}
          </p>
        </div>
        <div></div>
      </div>
      <SongsPanel @navigate-to-song="navigateToSong" :songs="album.songs" :albumOrderIndex="album.orderIndex" :bandAbbrv="bandAbbrv" />
      <JeffsExplanationPanel v-if="album.explanation" :bandAbbrv="bandAbbrv" :explanationText="album.explanation" />
      <MoreInfoPanel v-if="album.moreInfo" :bandAbbrv="bandAbbrv" :infoText="album.moreInfo" />
    </div>
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import SongsPanel from "@/components/common/panels/SongsPanel";
import MoreInfoPanel from "@/components/common/panels/MoreInfoPanel";
import JeffsExplanationPanel from "@/components/common/panels/JeffsExplanationPanel";
import DataRetrieval from "@/data/DataRetrieval";
import TransitionEngine from "@/common/TransitionEngine";

export default {
  name: "Album",
  components: {
    SongsPanel,
    Header,
    MoreInfoPanel,
    JeffsExplanationPanel,
  },
  props: {
    bandEnum: Number,
    bandAbbrv: String,
  },
  data() {
    return {
      albumOrderIndex: this.$route.params.albumOrderIndex,
      album: {
        name: "",
        orderIndex: 0,
      },
    };
  },
  created() {
    if (process.env.VUE_APP_USE_API === "true") {
      this.getAlbumData();
    } else {
      this.getStaticAlbumData()
    }
  },
  mounted() {
    TransitionEngine.onPanelMount();
  },
  methods: {
    navigateToBand() {
      TransitionEngine.triggerAnimationOnContainer("album-container", "fadeOut", "faster");
      setTimeout(() => this.$router.push({ name: `band-${this.$props.bandAbbrv}` }), 300);
    },
    navigateToSong(songOrderIndex) {
      TransitionEngine.triggerAnimationOnContainer("album-container", "fadeOut", "faster");
      setTimeout(() => this.$router.push({
        name: `song-${this.$props.bandAbbrv}`,
        params: { albumOrderIndex: this.albumOrderIndex, songOrderIndex: songOrderIndex },
      }), 300);
    },
    getAlbumUrl() {
      const images = require.context("@/assets/album-images", true, /\.jpg$/);
      return images(`./${this.$props.bandAbbrv}/` + this.albumOrderIndex + ".jpg");
    },
    getBandUrl() {
      const images = require.context("@/assets/bands-component", false, /\.png$/);
      return images(`./${this.$props.bandAbbrv}-hover.png`);
    },
    getStaticAlbumData() {
      this.album = DataRetrieval.retrieveAlbumData(this.$props.bandAbbrv, this.albumOrderIndex);
    },
    async getAlbumData() {
      const response = await fetch(`${process.env.VUE_APP_API_URL}bands/${this.$props.bandAbbrv}/albums/${this.albumOrderIndex}`);
      const albumData = await response.json();
      this.album = albumData;
    }
  },
};
</script>

<style scoped>
@import "../../main.css";

.album-image {
  max-height: 40vh;
  filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.5));
}

.album-title {
  font-family: ManlyMenBB;
  font-size: 2em;
  letter-spacing: -0.05em;
  text-align: center;
  margin: 0;
}

.album-title-line {
  margin: 0 15% 0 15%;
}

.description-text {
  margin: 1em 2em 0 2em;
}

.album-title-container {
  margin: 0 1em 0 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.back-band-image {
  max-height: 8vh;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  transform: rotate(-5deg);
  z-index: 3;
  margin-top: -1em;
  margin-bottom: -1em;
  margin-left: 1em;
  filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.5));
}

.back-band-image.asob {
  max-height: 14vh;
  margin-top: -4em;
  margin-bottom: -4em;
}

.back-band-image.btmi {
  max-height: 10vh;
  margin-top: -1em;
  margin-bottom: -1em;
}

.back-band-image.av {
  max-height: 12vh;
  margin-top: -3em;
  margin-bottom: -3em;
}

</style>